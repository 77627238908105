/* @font-face {
  font-family: "BigCasion";
  src: local("BigCasion"),
    url(./fonts/BigCaslon/BigCaslonRegular.otf) format("opentype");
}
@font-face {
  font-family: "Avenir";
  src: local("Avenir"),
    url(./fonts/Avenir/AvenirNextLTProRegular.otf) format("opentype");
} */
/* 
@font-face {
  font-family: 'Mona Sans';
  src: local("Mona Sans"),
    url(./fonts/Mona-Sans.woff2) format('woff2 supports variations'),
    url(./fonts/Mona-Sans.woff2) format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 100%;
}

@font-face {
  font-family: "Avenir Black";
  src: local("Avenir Black"),
    url(./fonts/avenir/AvenirBlack/AvenirBlack.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Book";
  src: local("Avenir Book"),
    url(./fonts/avenir/AvenirBook/AvenirBook.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Light";
  src: local("Avenir Light"),
    url(./fonts/avenir/AvenirLight/AvenirLight.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Medium";
  src: local("Avenir Medium"),
    url(./fonts/avenir/AvenirMedium/AvenirMedium.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Regular";
  src: local("Avenir Regular"),
    url(./fonts/avenir/AvenirRegular/AvenirRegular.ttf) format("truetype");
} */

@font-face {
  font-family: "Futura Md BT";
  src: local("Futura Md BT"),
    url(./fonts/futura/futura\ medium\ bt.ttf) format("truetype");
}
@font-face {
  font-family: "Futura BK BT";
  src: local("Futura BK BT"),
    url(./fonts/futura/Futura\ Extra\ Black\ font.ttf) format("truetype");
}
@font-face {
  font-family: "Futura Book";
  src: local("Futura Book"),
    url(./fonts/futura/Futura\ Book\ font.ttf) format("truetype");
}
@font-face {
  font-family: "The Seasons";
  src: local("The Seasons"),
    url(./fonts/Demo_Fonts/Fontspring-DEMO-theseasons-reg.otf)
      format("opentype");
}

/* @font-face {
    font-family: 'Lato';
    font-weight: 900;
    src: local('Lato'), url(./fonts/Lato-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Lato';
    font-weight: 900;
    src: local('Lato'), url(./fonts/Lato-Black.otf) format('opentype');
} */
.App {
  text-align: center;
}
/* .container {
  position: relative;
} */

video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  filter: brightness(0.8);
  /* filter: brightness(75%); */
}
.upperText {
  /* font-family: "BigCasion"; */
  font-weight: 700;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;

  color: rgb(255, 255, 255);
  /* -webkit-text-stroke: 1px; */
  font-size: 5rem;
  /* font-size: "86px"; */
}
.upperTextBlack {
  /* font-family: "BigCasion"; */
  font-weight: 600;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;

  color: rgb(0, 0, 0);
  /* -webkit-text-stroke: 1px; */
  font-size: 2.5rem;
  /* font-size: "86px"; */
}
.lowerText {
  /* font-family: "Avenir"; */
  font-weight: 200;
  /* text-align: "center"; */
  /* align: "center"; */
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
  /* -webkit-text-stroke: 1px; */
  /*  -webkit-text-fill-color: transparent;
   /* -webkit-text-stroke: 1px; */
}

.overlayUpperB {
  position: absolute;
  top: 50%;
  /* text-align: center; */
  right: 37%;
  z-index: 1000;
  color: rgb(255, 255, 255);
  /* -webkit-text-stroke: 1px; */
}
.overlayUpperBlack {
  position: absolute;
  top: 50%;
  /* text-align: center; */
  right: 20%;
  z-index: 1000;
  color: rgb(255, 255, 255);
  /* -webkit-text-stroke: 1px; */
}
.linkStyle {
  /* font-family: "Avenir"; */
  /*  color: rgb(255, 255, 255);
   /* -webkit-text-stroke: 1px; */
  color: rgb(255, 255, 255);
  font-size: 1.3rem;
}
.overlayUpper {
  position: absolute;
  text-align: center;
  top: 23%;
  /* justify-content: center;
  align-items: center; */
  right: 41%;
  z-index: 1000;
}
.overlayUpperImg {
  position: absolute;
  text-align: center;
  top: 10%;
  right: 40%;
  z-index: 1000;
}
.overlay {
  position: absolute;
  bottom: 200px;
  left: 45%;
  z-index: 1;
}
a:link {
  font-style: italic;
  text-decoration: underline;
  color: rgb(255, 255, 255);
  /* color: "#223211"; */
  /*  color: rgb(255, 255, 255);
   /* -webkit-text-stroke: 1px; */
  font-weight: 600;
}
@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
}
@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}
@media only screen and (max-device-width: 480px) {
  /* for  (tab) screens */
}
@media only screen and (device-width: 768px) {
  /* default iPad screens */
}
/* different techniques for iPad screening */
@media only screen and (min-device-width: 300px) and (max-device-width: 700px) /* and (orientation: portrait) { */ {
  #featured {
    max-height: 100vh;
    overflow: hidden;
    text-align: center;
  }

  .video {
    position: absolute;
    /* top: 0; */
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .upperTextBlack {
    /* font-family: "BigCasion"; */
    font-weight: 600;
    text-align: center;
    justify-content: center;
    align-self: center;
    align-items: center;

    color: rgb(0, 0, 0);
    /* -webkit-text-stroke: 1px; */
    font-size: 2.5rem;
    /* font-size: "86px"; */
  }
  .overlayUpperBlack {
    position: absolute;
    top: 75%;
    /* text-align: center; */
    right: 5%;
    z-index: 1000;
    color: rgb(255, 255, 255);
    /* -webkit-text-stroke: 1px; */
  }
  .upperText {
    /* font-family: "BigCasion"; */
    text-align: center;
    justify-content: center;
    align-self: center;
    align-items: center;
    color: rgb(255, 255, 255);
    /* -webkit-text-stroke: 1px; */
    font-size: 3.5rem;
    /* font-size: "86px"; */
  }
  .lowerText {
    /* font-family: "Avenir"; */
    /* text-align: "center"; */
    /* align: "center"; */
    font-size: 1.2rem;
    font-weight: 200;
    color: rgb(255, 255, 255);
    /* -webkit-text-stroke: 1px; */
  }

  .overlayUpperB {
    position: absolute;
    top: 54%;
    text-align: center;
    left: 15%;
    right: 18%;
    z-index: 1000;
    color: rgb(255, 255, 255);
    /* -webkit-text-stroke: 1px; */
  }
  .linkStyle {
    /* font-family: "Avenir"; */
    color: "#223211";
    /*  color: rgb(255, 255, 255);
     /* -webkit-text-stroke: 1px; */
    font-size: 1.3rem;
  }
  .overlayUpper {
    position: absolute;
    text-align: center;
    top: 35%;
    right: 21%;
    z-index: 1000;
  }
  .overlayUpperImg {
    position: absolute;
    text-align: center;
    top: 10%;
    right: 40%;
    z-index: 1000;
  }

  .overlay {
    position: absolute;
    bottom: 30px;
    left: 18%;
    z-index: 1;
  }
}

@media only screen and (min-device-width: 701px) and (max-device-width: 1100px) /* and (orientation: landscape) { */ {
  #featured {
    max-height: 100vh;
    overflow: hidden;
    text-align: center;
  }

  video {
    min-width: 100%;
    min-height: 100%;
  }
  .upperTextBlack {
    /* font-family: "BigCasion"; */
    font-weight: 600;
    text-align: center;
    justify-content: center;
    align-self: center;
    align-items: center;

    color: rgb(0, 0, 0);
    /* -webkit-text-stroke: 1px; */
    font-size: 2.5rem;
    /* font-size: "86px"; */
  }
  .overlayUpperBlack {
    position: absolute;
    top: 60%;
    /* text-align: center; */
    right: 25%;
    z-index: 1000;
    color: rgb(255, 255, 255);
    /* -webkit-text-stroke: 1px; */
  }
  .upperText {
    /* font-family: "BigCasion"; */
    text-align: center;
    justify-content: center;
    align-self: center;
    align-items: center;
    color: rgb(255, 255, 255);
    /* -webkit-text-stroke: 1px; */
    font-size: 3.5rem;
    /* font-size: "86px"; */
  }
  .lowerText {
    /* font-family: "Avenir"; */
    text-align: "center";
    align-items: "center";
    font-size: 1.3rem;
    color: rgb(255, 255, 255);
    /* -webkit-text-stroke: 1px; */
  }

  .overlayUpperB {
    position: absolute;
    top: 55%;
    text-align: center;
    left: 24%;
    z-index: 1;
    color: rgb(255, 255, 255);
    /* -webkit-text-stroke: 1px; */
  }
  .linkStyle {
    /* font-family: "Avenir"; */
    color: "#223211";
    /*  color: rgb(255, 255, 255);
     /* -webkit-text-stroke: 1px; */
    font-size: 1.3rem;
  }
  .overlayUpper {
    position: absolute;
    text-align: center;
    top: 30%;
    right: 40%;
    z-index: 1000;
  }
  .overlayUpperImg {
    position: absolute;
    text-align: center;
    top: 10%;
    right: 40%;
    z-index: 1000;
  }

  .overlay {
    position: absolute;
    bottom: 30px;
    left: 18%;
    z-index: 1;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* #vid {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1000;
  overflow: hidden;
} */

/* 
.input {
  border-radius: 15px;
  font-family: "Open Sans",  sans-serif;
  font-size: 16px;
  text-align: center;
  border: none;
  height: 6vh;
  width: 40vw;
}
.mainInput {
  position: relative;
  margin-bottom: 80%;
   margin-top: 0%; 
} */
button,
.MuiButtonBase-root {
  text-transform: capitalize !important;
}
