/* @font-face {
  font-family: "Avenir Black";
  src: local("Avenir Black"),
    url(./fonts/avenir/AvenirBlack/AvenirBlack.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Book";
  src: local("Avenir Book"),
    url(./fonts/avenir/AvenirBook/AvenirBook.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Light";
  src: local("Avenir Light"),
    url(./fonts/avenir/AvenirLight/AvenirLight.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Medium";
  src: local("Avenir Medium"),
    url(./fonts/avenir/AvenirMedium/AvenirMedium.ttf) format("truetype");
}
@font-face {
  font-family: "Avenir Regular";
  src: local("Avenir Regular"),
    url(./fonts/avenir/AvenirRegular/AvenirRegular.ttf) format("truetype");
} */

@font-face {
  font-family: "Futura Md BT";
  src: local("Futura Md BT"),
    url(./fonts/futura/futura\ medium\ bt.ttf) format("truetype");
}
@font-face {
  font-family: "Futura BK BT";
  src: local("Futura BK BT"),
    url(./fonts/futura/Futura\ Extra\ Black\ font.ttf) format("truetype");
}
@font-face {
  font-family: "Futura Book";
  src: local("Futura Book"),
    url(./fonts/futura/Futura\ Book\ font.ttf) format("truetype");
}
@font-face {
  font-family: "The Seasons";
  src: local("The Seasons"),
    url(./fonts/Demo_Fonts/Fontspring-DEMO-theseasons-reg.otf)
      format("opentype");
}
@font-face {
  font-family: "Mona Sans";
  src: local("Mona Sans"),
    url(./fonts/monaSans/Mona-Sans.woff2) format("woff2 supports variations"),
    url(./fonts/monaSans/Mona-Sans.woff2) format("woff2-variations");
  font-weight: 200 900;
  font-stretch: 100%;
}
body {
  font-family: "Mona Sans", Arial, sans-serif !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  display: flex;
  position: relative;
  font-family: "Mona Sans", Arial, sans-serif !important;
  /* overflow-y: scroll;
  overflow-x: scroll; */
}
.content {
  /* overflow-y: scroll;
  overflow-x: scroll; */
  height: 100%;
  width: 100%;
}
