.yes-b{

border:1px solid #693BE1;
background-color:white;
color:#693BE1;
padding:8px 30px;
border-radius: 7px;
font-size: 18px;
}

.no-b{
border:1px solid #fff;
padding:8px 30px;
border-radius: 7px;
color:#fff;
background-color: transparent;
font-size: 18px;

}
