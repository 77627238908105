.header{


   border: 1px solid #EDEDED;
    padding:15px 0px 15px 21px;
    }
    .headerSearch{
    height:24px;
    width:95%;
    margin-right: 15px;
    border-radius:7px;
    /* outline:none; */
    background: #FAFAFA;
    padding:0 0 0 10%;
    
    color:#8A8993;
    outline:none;
    border:1px solid white;
    }
    input:focus{
    
    outline:none;
    }
    
    @media  only screen and (min-width:900px){
    
    .navBar {
    display: none;
    }
        
    }

    .navList{
        padding:4px 0px 4px 0px;
        border-radius: 8px; 
        
        cursor: pointer;
        }
   .headerli{
padding:7px 12px
}

.selectheaderli{
padding:7px 12px;
background-color: #1DB954;

}
.liveWord {
   font-weight: bolder;
   text-transform: uppercase;
   padding: 1.5px 1.5px 0 1.5px;
   color: red;
   border: 2px solid red;
   animation: highlight 0.8s infinite alternate;
   margin-right: 4px;
 }
 @keyframes highlight {
   0% {
     color: red;
     background-color: transparent;
   }
   100% {
     color: white;
     background-color: red;
   }
 }  